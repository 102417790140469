.warning-modal {
  /* Define the animation for scrolling */
  @keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

.scrollingContainer {
  overflow: hidden; /* Hide the text when it moves out of view */
  width: 100%; /* Full width for container */
  position: relative;
  height: 2rem; /* Adjust height to fit the text */
}

.scrollingWarning {
  color: white;
  font-size: 0.8rem;
  white-space: nowrap;
  display: inline-block; /* Ensures the text width fits its content */
  position: absolute; /* Position it absolutely for continuous scrolling */
  animation: scroll-left 15s linear infinite;
}
