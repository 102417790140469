//
// Alert
//

.alert {

    font-size: $font-size-sm;
}

// Alert heading

.alert-heading {
    font-weight: $font-weight-bold;
    font-size: $h4-font-size;
    margin-top: .15rem;
}


// Alert icon

.alert-icon {
    font-size: 1.25rem;
    margin-right: 1.25rem;
    display: inline-block;
    vertical-align: middle;

    i.ni {
        position: relative;
        top: 2px;
    }
}


// Alert text next to an alert icon

.alert-text {
    display: inline-block;
    vertical-align: middle;
}


// Alert links

[class*="alert-"] {
    .alert-link {
        color: $white;
        border-bottom: 1px dotted rgba($white, .5);
    }
}

// close button for Swal alert
.custom-close-button {
    font-size: 25px;  /* Adjust this to control the size (default is 40px) */
    width: 30px;      /* Control the width */
    height: 30px;     /* Control the height */
    line-height: 30px;/* Ensure it's centered properly */
  }