
// Container for responsive YouTube video
.video-container {
    position: relative;  //this allows iframe to be as absolute positioning
    padding-bottom: 56.25%;  // 16:9 aspect ratio for responsive sizing
    height: 0;  // Zero height, padding adds the height
    overflow: hidden; // Hides any overflow content
    max-width: 100%; // Container width scales with parent
    background: #000; // Black background for video area
}

.video-container iframe {
    position: absolute; // Positioned within the container
    top: 0;
    left: 0;
    width: 100%; // Full width of container
    height: 100%; // Full height of container
}