@media (min-width: 1024px) {
  /* width */
  //   ::-webkit-scrollbar:horizontal {
  //     height: 0.625rem;
  //     width: 0.625rem;
  //     background-color: transparent;
  //   }
  ::-webkit-scrollbar {
    height: 0.938rem;
    width: 0.938rem;
    background-color: white;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 0.625rem;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 0.625rem;
    border: solid;
    border-color: white;
  }
  /* Hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(160, 160, 160);
  }
  /* Support for legacy IE/Edge */
  ::-ms-scrollbar {
    width: 0.938rem;
    height: 0.938rem;
    background-color: lightgrey;
  }
  /* Support for any other browers */
  //   @supports (scrollbar-width: thin) {
  //     * {
  //       scrollbar-track-color: transparent;
  //       scrollbar-arrow-color: transparent;
  //       scrollbar-width: thin; /* Slim scrollbar */
  //       scrollbar-color: lightgrey transparent; /* Thumb and track colors */
  //     }
  //   }
}
